import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../App";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import files from "../static";
import V1 from '../authbg.mp4';
import HOST from "../host";
import Cookies from "js-cookie";
import { logToServer } from "../logger";
import MapboxExample from "../Authforms/MapExample"
export default function Login() {
  const navigate = useNavigate();
  const [member_email, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { setUserInfo, userInfo, getCsrfToken } = useContext(GlobalContext);
  const [loader, setLoader] = useState(false);
  function redirect() {
    navigate("/forget-password")
  }
  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }
  const loginUser = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (!member_email || !password) {
      toast.warn("Please enter both email and password.");
      setLoader(false);
      return;
    }
    const formData = {
      "member_email": member_email,
      "password": password,
    }
    try {
      const res = await fetch(`${HOST}/login/`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': await getCsrfToken(),
        },
      });
      const data = await res.json();
      if (res.status === 400 || res.status === 500 || !data || data.error) {
        toast.error(`${data.error}`);
        logToServer('error', `${data.error}`);
      } else if (res.status === 200) {
        toast.success(`Welcome ${data.user_info.first_name}`);
        logToServer('info', 'login Successfully');
        setUserInfo(data.user_info);
      }
    } catch (e) {
      logToServer('error', `${e}`);
      toast.error("Invalid credentials");
    } finally {
      setLoader(false); // Ensure loader is hidden regardless of success or failure
    }
  };
  return (
    <div className="main">
      <div className="left-side">
        {/* <MapboxExample /> */}
        <video src={V1} autoPlay loop muted />
      </div>
      <div className="right-side">
        <div className="logo">
          <img src={`${process.env.PUBLIC_URL}/${files}vgtlogo.png`} alt="Vasundharaa Logo" />
        </div>
        <form className="login-form">
          <input
            style={{ opacity: loader ? '0.3' : '1' }}
            value={member_email}
            className="mt-3"
            onChange={(e) => setUserEmail(e.target.value)}
            type="email" placeholder="name@gmail.com" required />
          <div className="pass-container">
            <input
              type={showPassword ? "text" : "password"}
              style={{ opacity: loader ? '0.3' : '1' }}
              className="type-pass"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password" required />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <i className="fa fa-eye-slash" title="Hide Password"></i>
                ) : (
                  <i className="fa fa-eye" title="Show Password"></i>
                )}
              </button>
          </div>
          <div className="pass-section">
            {/* <span>
              <input style={{ opacity: loader ? '0.3' : '1' }} type="checkbox" id="remember-me" />
              Remember me
            </span> */}
            <span onClick={() => navigate('/forget-password')} style={{ opacity: loader ? '0.3' : '1' }} className="reset" >Forget password ? </span>
          </div>
          <button type="submit" onClick={(e) => loginUser(e)} className="button">Sign In</button>
          <div onClick={() => navigate('/signup')} className="signup-link">
            Don’t have an account? <span>Sign up</span>
          </div>
        </form>
      </div>
    </div>
  );

}