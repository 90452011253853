import React from "react";
import { NavLink } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <div
      className="text-white text-center p-1"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        backgroundImage:
          "radial-gradient(circle farthest-corner at center, #3c4b57 0, #1c262b 100%)",
      }}
    >
      <div style={{ padding: "5%", textAlign: "justify", width: "100%" }}>
        <h2 style={{ textAlign: "center" }}>Privacy Policy</h2>
        <br />
        <p>
          This Privacy Policy describes how Vasundharaa Geo Technologies Private
          Limited and its affiliates (collectively "Vasundharaa Geo Technologies
          Private Limited, we, our, us") collect, use, share, protect or
          otherwise process your information/personal data through our website
          “portal.vasundharaa.in” (hereinafter referred to as Platform). Please
          note that you may be able to browse certain sections of the Platform
          without registering with us. We do not offer any product/service under
          this Platform outside India, and your personal data will primarily be
          stored and processed in India. By visiting this Platform, providing
          your information, or availing of any product/service offered on the
          Platform, you expressly agree to be bound by the terms and conditions
          of this Privacy Policy, the Terms of Use, and the applicable
          service/product terms and conditions, and agree to be governed by the
          laws of India, including but not limited to the laws applicable to
          data protection and privacy. If you do not agree, please do not use or
          access our Platform.
        </p>
        <h5> Collection</h5>
        <p>
          {" "}
          We collect your personal data when you use our Platform, services, or
          otherwise interact with us during the course of our relationship. Some
          of the information that we may collect includes but is not limited to
          personal data/information provided to us during sign-up/registering or
          using our Platform, such as name, date of birth, address,
          telephone/mobile number, email ID, and/or any such information shared
          as proof of identity or address. Some of the sensitive personal data
          may be collected with your consent, such as your bank account or
          credit or debit card information or biometric information such as your
          facial features or physiological information (in order to enable
          certain features when opted for, available on the Platform) etc. All
          of the above being in accordance with applicable law(s). You always
          have the option to not provide information by choosing not to use a
          particular service or feature on the Platform. We may track your
          behavior, preferences, and other information that you choose to
          provide on our Platform.
        </p>
        <h5>Usage</h5>
        <p>
          We use personal data to provide the services you request. To the
          extent we use your personal data to market to you, we will provide you
          the ability to opt-out of such uses. We use your personal data to
          assist sellers and business partners in handling and fulfilling
          orders, enhance customer experience, resolve disputes, troubleshoot
          problems, inform you about online and offline offers, products,
          services, and updates, customize your experience, detect and protect
          us against error, fraud, and other criminal activity, enforce our
          terms and conditions, conduct marketing research, analysis and
          surveys, and as otherwise described to you at the time of collection
          of information.
        </p>
        <h5>Sharing</h5>
        <p>
          We may share your personal data internally within our group entities,
          our other corporate entities, and affiliates to provide you access to
          the services and products offered by them. These entities and
          affiliates may market to you as a result of such sharing unless you
          explicitly opt-out. We may disclose personal data to third parties
          such as sellers, business partners, third-party service providers
          including logistics partners, prepaid payment instrument issuers,
          third-party reward programs, and other payment options chosen by you.
          These disclosures may be required for us to provide you access to our
          services and products, comply with our legal obligations, enforce our
          user agreement, facilitate marketing and advertising activities, or
          prevent, detect, mitigate, and investigate fraudulent or illegal
          activities related to our services.{" "}
        </p>
        <h5>Security Precautions </h5>
        <p>
          To protect your personal data from unauthorized access or disclosure,
          loss, or misuse, we adopt reasonable security practices and
          procedures. Once your information is in our possession or whenever you
          access your account information, we adhere to our security guidelines
          to protect it against unauthorized access and offer the use of a
          secure server. However, the transmission of information is not
          completely secure for reasons beyond our control. By using the
          Platform, the users accept the security implications of data
          transmission over the internet and the World Wide Web, which cannot
          always be guaranteed as completely secure.
        </p>
        <h5> DataDeletion and Retention</h5>
        <p>
          You have an option to delete your account by visiting your profile and
          settings on our Platform. This action would result in losing all
          information related to your account. We may refuse or delay the
          deletion of the account if there is a pending grievance, claim, or
          pending shipment. We retain your personal data for as long as
          necessary for the purposes it was collected for or as required by
          applicable law. In anonymized form, your data may be retained for
          research purposes.
        </p>
        <h5> Your Rights </h5>
        <p>
          {" "}
          You may access, rectify, and update your personal data directly
          through the functionalities provided on the Platform.
        </p>
        <h5> Consent </h5>
        <p>
          By visiting our Platform or by providing your information, you consent
          to the collection, use, storage, disclosure, and otherwise processing
          of your information in accordance with this Privacy Policy. If you
          disclose to us any personal data relating to other people, you
          represent that you have the authority to do so and permit us to use
          the information in accordance with this Privacy Policy.
        </p>
        <h5> Changes to this Privacy Policy </h5>
        <p>
          Please check our Privacy Policy periodically for changes. We may
          update this Privacy Policy to reflect changes in our information
          practices. We may notify you about significant changes to the Privacy
          Policy, as may be required by applicable laws.
        </p>
        <h5> Grievance Officer </h5>
        <p>Yuvraj Joshi Lead Engineer</p>
        <p>Vasundharaa Geo Technologies Private Limited, Pune, India</p>
        <strong>Contact us : </strong>yuvraj@dharaatech.in <br />
        <strong>Phone : </strong> 8379058188 <br />
        <strong>Time : </strong> Monday - Friday (9:00 -18:00) <br />
      </div>

      <div style={{ padding: "1% 5%", textAlign: "justify", width: "100%" }}>
        <h2 style={{ textAlign: "center" }}>Refund & Cancellation Policy</h2>
        <br />
        <p>
          This Refund and Cancellation Policy explains how you can request a
          refund or cancel a service or credit purchase through our platform. By
          using our services or making a purchase, you agree to the terms
          outlined in this policy.{" "}
        </p>
        <h5>1.Cancellations </h5>
        <p>
          You may request a cancellation within 7 days of purchasing credits or
          services. However, cancellation requests may not be honored if the
          service or action has already been initiated or processed. In cases
          where no service has been rendered, the cancellation will be accepted,
          and your credits refunded accordingly.{" "}
        </p>
        <h5> 2. Service Issues or Defective Credits</h5>
        <p>
          If you encounter an issue with the credits purchased or the service is
          defective (i.e., unable to perform the desired action), please contact
          our customer support team within 7 days of the issue. We will review
          the problem and take the necessary steps to either resolve the issue
          or process a refund.{" "}
        </p>
        <h5>3. Services Not as Expected</h5>
        <p>
          {" "}
          If the service or action performed does not meet your expectations or
          does not align with the description provided, you may notify our
          customer service team within 7 days of the transaction. We will review
          your claim and determine if a refund or adjustment is warranted.{" "}
        </p>
        <h5>4. Refund Processing </h5>
        <p>
          If your refund request is approved, refunds will be processed within
          10 days. The refund will be made to the original payment method, or in
          the form of credits, based on the circumstances.
        </p>
        <h5> 5. Unused Credits </h5>
        <p>
          In the event you have purchased credits but have not used them, you
          may request a refund within 30 days of the purchase, provided that the
          credits are unused. Any used portion of the credits will not be
          eligible for a refund.
        </p>
        <h5>6. Partial Refunds </h5>
        <p>
          Partial refunds may be granted for cases where only a portion of the
          credits have been used or when a service has been partially completed.
          This policy ensures transparency and fairness for users who purchase
          credits and perform actions on your geo portal platform.
        </p>
      </div>
      <br />
      <div style={{ padding: "1% 5%", textAlign: "justify", width: "100%" }}>
        <h2 style={{ textAlign: "center" }}>Terms & Conditions</h2>
        <br />
        <p>
          This document is an electronic record in terms of the Information
          Technology Act, 2000, and the rules thereunder as applicable,
          including the amended provisions related to electronic records in
          various statutes as amended by the Information Technology Act, 2000.
          This electronic record is generated by a computer system and does not
          require any physical or digital signatures. This document is published
          in accordance with the provisions of Rule 3 (1) of the Information
          Technology (Intermediaries Guidelines) Rules, 2011, which require the
          publication of the rules, regulations, privacy policy, and Terms of
          Use for access to or usage of the geo portal application at
          “portal.vasundharaa.in” (hereinafter referred to as the “Website” or
          “Platform”), including the related mobile application. The Platform is
          owned by Vasundharaa Geo Technologies Private Limited, a company
          incorporated under the Companies Act, 1956, with its registered office
          in Pune, India (hereinafter referred to as the “Platform Owner,” “we,”
          “us,” or “our”). By using the Platform or purchasing services/credits
          through it, you agree to the following Terms of Use, which govern your
          access and use of our services, including all applicable policies
          referenced herein. These Terms of Use form a legally binding contract
          between you (“User,” “you,” or “your”) and the Platform Owner when you
          use our geo portal services.
        </p>

        <h5>1. Modifications to Terms of Use</h5>
        <p>
          We reserve the right to modify these Terms of Use at any time without
          providing specific reasons. It is your responsibility to review these
          Terms periodically to remain informed of updates.
        </p>

        <h5>2. User Account and Obligations </h5>
        <p>
          To access and use the Platform and services, you must provide true,
          accurate, and up-to-date information during registration. You are
          responsible for all activities conducted using your registered
          account. Ensure that your account credentials are kept secure.
        </p>

        <h5>3. No Warranties</h5>
        <p>
          We do not provide any guarantees regarding the accuracy, performance,
          or suitability of the information and materials offered through the
          Platform. You acknowledge that such information may contain
          inaccuracies, and we disclaim liability for any errors to the fullest
          extent permitted by law.{" "}
        </p>

        <h5> 4. Use of Services and Platform</h5>
        <p>
          Your use of the Platform and associated services is solely at your own
          discretion and risk. You agree to ensure that the services offered on
          the Platform meet your specific requirements, and we bear no liability
          for any dissatisfaction with the services.
        </p>

        <h5>5. Intellectual Property</h5>
        <p>
          The content on the Platform, including design, layout, text, and
          graphics, is proprietary and licensed to us. You agree not to claim
          any intellectual property rights over the content and will not use any
          content without our explicit permission.
        </p>
        <h5>6. Service Charges and Payments</h5>
        <p>
          You agree to pay the associated charges for purchasing credits or
          using the services on the Platform. Refund and cancellation policies
          are outlined separately and apply to all transactions made on the
          Platform.
        </p>
        <h5>7. Prohibited Use</h5>
        <p>
          You agree not to use the Platform or services for any unlawful,
          illegal, or unauthorized purpose as per these Terms and applicable
          Indian laws.
        </p>
        <h5>8. Third-Party Links</h5>
        <p>
          The Platform may contain links to third-party websites for
          convenience. By clicking these links, you agree to abide by the terms
          and policies of the third-party websites, as we do not control their
          content or offerings.
        </p>
        <h5>9. Legal Agreement and Transactions</h5>
        <p>
          Upon initiating any transaction for purchasing credits or availing of
          services through the Platform, you enter into a legally binding
          contract with us. You are required to complete your obligations
          related to that transaction.
        </p>
      </div>
      <div
        style={{
          padding: "0% 5% 2%",
          width: "100%",
          display: "flex",
          justifyContent: "left",
        }}
      >
        <NavLink
          to="/portal"
          className="btn btn-primary"
        >
          Get Started
        </NavLink>
        <NavLink
          to="mailto:service@dharaatech.in"
          className="btn btn-primary"
          style={{ marginLeft: "1%" }}
        >
          Contact US
        </NavLink>
      </div>
    </div>
  );
}
