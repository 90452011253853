import React, { useState, useEffect,useContext } from 'react';
import CsvToHtmlTable from './CsvTable';
import { GlobalContext } from '../../App';
const ResizableDiv = ({ csvData, setTable, setTableData, name }) => {
  const [size, setSize] = useState({ width: 300, height: 400 }); // Initial width and height
  const [maxHeight, setMaxHeight] = useState(380); // State to hold maxHeight dynamically
  const [isResizing, setIsResizing] = useState(false);
  const {Canvas}=useContext(GlobalContext)
  const handleMouseDown = (e) => {
    setIsResizing(true);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      setSize((prevSize) => ({
        width: Math.max(200, prevSize.width + e.movementX), // Minimum width of 200px
        height: Math.max(200, prevSize.height + e.movementY) // Minimum height of 200px
      }));

      // Dynamically update maxHeight during resizing
      setMaxHeight(Math.max(200, size.height + e.movementY));
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  // Add event listeners for mouse move and mouse up
  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, size.height]); // Watch for changes in size.height

  return (
    <div
      style={{
        position: 'absolute',
        top: '10px',
        left: '10px',
        zIndex: '1000',
        backgroundColor: 'white',
        borderRadius: '3px',
        padding: '1vh',
        boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.1)',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: size.width, // Dynamically set width
        height: size.height // Dynamically set height
      }}
    >
      <div style={{ position: 'absolute', top:  '5px', left: '5px' }}>
        <button
          title="close"
          className="btn"
          onClick={() => {
            setTable(false);
            setTableData(null);
            if(Canvas){
              Canvas.RemoveHighlight(name)
            }
          }}
          style={{
            color: 'black',
            backgroundColor: '#397AA5',
            fontSize:  '15px',
            padding: '2px 2px',
            width:  '30px',
            height:  '30px',
            borderRadius: '10%'
          }}
        >
          &times;
        </button>
      </div>

      
        <CsvToHtmlTable
          data={csvData}
          csvDelimiter=","
          tableClassName="table table-striped table-hover"
          name={name}
          maxHeight={maxHeight}
        />
     

      {/* Resizing handle */}
      <div
        style={{
          position: 'absolute',
          bottom: '5px', // Keep it at the bottom
          right: '5px',  // Keep it at the right
          width: '10px',
          height: '10px',
          cursor: 'se-resize',
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }}
        onMouseDown={handleMouseDown}
      />
    </div>
  );
};

export default ResizableDiv;
