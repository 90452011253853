import React, { useEffect, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import HOST from "../host";

function Payment2() {
  const { transactionId } = useParams();
  const [paymentStatus, setPaymentStatus] = useState(null);  // Store payment status
  const [loading, setLoading] = useState(true);  // Show loading state
  const [error, setError] = useState(null);  // Store any error during fetch

  useEffect(() => {
    // Fetch payment status using the transactionId
    const fetchPaymentStatus = async () => {
      try {
        const response = await fetch(`${HOST}/payment-status/${transactionId}`);
        const result = await response.json();
        if (response.ok) {
          setPaymentStatus(result);  // Set the payment status
        } else {
          setError("Error fetching payment status");
        }
      } catch (err) {
        setError("Failed to fetch payment status. Please try again.");
      } finally {
        setLoading(false);  // Hide the loading indicator once done
      }
    };

    fetchPaymentStatus();
  }, [transactionId]);

  if (loading) {
    return <p style={{ color: "white" }}>Loading payment status...</p>;
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Payment Status</h2>
      {paymentStatus ? (
        <div style={styles.statusContainer}>
          <p><strong>Transaction ID:</strong> {transactionId}</p>
          <p><strong>Credit Amount:</strong> {paymentStatus.amount}</p>
          <p><strong>Status:</strong> {paymentStatus.status}</p>
          {paymentStatus.method && (
            <p><strong>Payment Method:</strong> {paymentStatus.method}</p>
          )}
          {paymentStatus.utr && (
            <p><strong>UTR:</strong> {paymentStatus.utr}</p>
          )}
        </div>
      ) : (
        <p style={{ color: "white" }}>No payment status found.</p>
      )}
      {/* Add link to navigate to /user-console */}
      <Link to="/user-console" style={styles.link}>
        Go to User Console
      </Link>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: "#282c34",
    color: "white",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    width: "100%",
    maxWidth: "600px",
    margin: "0 auto",
    marginTop: "50px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  statusContainer: {
    fontSize: "18px",
    lineHeight: "1.6",
  },
  link: {
    display: "inline-block",
    marginTop: "20px",
    color: "#61dafb",
    textDecoration: "none",
    fontSize: "16px",
    padding: "10px 20px",
    backgroundColor: "#20232a",
    borderRadius: "5px",
    border: "1px solid #61dafb",
  },
};

export default Payment2;
