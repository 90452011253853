import React, { useEffect, useState, useContext } from 'react';
import HOST from '../host';
import './log.css';
import { GlobalContext } from '../../App';

const UP42ORDERS = () => {
  const { userInfo } = useContext(GlobalContext);
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'timestamp', direction: 'desc' });
  const [loading, setLoading] = useState(false);
  const [loader, setloader] = useState(false);
  const [error, setError] = useState(null);

  // Fetch logs from API
  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${HOST}/get-orders-up42/${userInfo.id}`);
      if (!response.ok) throw new Error('Failed to fetch Orders');
      const data = await response.json();
      setLogs(data.orders || []);
    } catch (err) {
      console.error('Error fetching logs:', err);
      setError('Failed to fetch logs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch assets for a specific order and initiate download
  const fetchAndDownloadAsset = async (orderId) => {
    try {
      setloader(true);
      const response = await fetch(`${HOST}/fetch-order-assets-up42/${orderId}`);
      if (!response.ok) throw new Error('Failed to fetch assets.');
      const data = await response.json();
      console.log(data)
      data["assets"].forEach((asset) => {
        const link = document.createElement('a');
        link.href = asset.url;
      link.download = asset.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (err) {
      console.error('Error downloading asset:', err);
      alert('Failed to download asset. Please try again.');
      setloader(false);
    }
    setloader(false);
  };
  const ToCloud = async (orderId) => {
    // Ask for confirmation before proceeding
   
    try{
      setloader(true);
  
      const response = await fetch(`${HOST}/cloud-order-assets-up42/${userInfo.id}/${orderId}`);
  
      if (!response.ok) {
       
        alert("Error Moving Data to Cloud.Please try again Later")
        return
      }
      alert("Moving process started. The data will be available on the cloud soon")

    } catch (error) {
      console.error('Error placing order:', error);
      alert("Error Moving Data to Cloud.Please try again Later")
    } finally {
      setloader(false);
    }
    setloader(false);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const sortedLogs = [...logs].sort((a, b) => {
    const valueA = a[sortConfig.key];
    const valueB = b[sortConfig.key];
    if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
    if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = sortedLogs.slice(indexOfFirstLog, indexOfLastLog);
  const totalPages = Math.ceil(logs.length / logsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  if (loading) return <div style={{color:"black"}}>Loading Orders....</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <>
    <div style={{flex: 1, display: "flex", justifyContent: "flex-end" }}>
        {loader && (
            <div style={{ opacity: "1", position: "relative",margin:'-3% 3% 5% 0' }}>
              <div className="lds-dual-ring">
                <i className="fa-solid fa-globe"></i>
              </div>
          </div>
        )}
      </div>
      <div className="all-tab-container">
        <div className="user-container">
          <div className="row">
            <div className="col-12">
              <table className="table w-100">
                <thead className="thead-light">
                  <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th onClick={() => handleSort('timestamp')}>
                      Timestamp {sortConfig.key === 'timestamp' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                {logs.length ? (
                  <tbody className="tbody">
                    {currentLogs.map((log) => (
                      <tr key={log.id}>
                        <td>{log.id}</td>
                        <td>{log.status}</td>
                        <td>
                          {new Date(log.time.replace('T', ' ').replace('Z', '')).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: true,
                          })}
                        </td>
                        <td>
                          {log.status === 'FULFILLED' ? (
                            <button onClick={() => fetchAndDownloadAsset(log.id)}>
                              Download
                            </button>
                          ) : (
                            null
                          )}
                        </td>
                        <td>
                          {log.status === 'FULFILLED' ? (
                            <button onClick={() => ToCloud(log.id)}>
                              To Cloud
                            </button>
                          ) : (
                            null
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody className="tbody">
                    <tr>
                      <td colSpan="4">No Data Available</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="pagination">
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          &lt;
        </button>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => paginate(page)}
            className={page === currentPage ? 'current-page' : ''}
          >
            {page}
          </button>
        ))}
        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
          &gt;
        </button>
      </div>
    </>
  );
};

export default UP42ORDERS;
